import React, {useState, useContext, useEffect} from 'react';
import {MainContext} from '../App';
import axios from 'axios';
// import Loading from '../components/loading/loading';

export const GetUserStatistics = () => {
  const CTX = useContext(MainContext);
  const [loadingStatistics, setLoading] = useState(false);
  const [data, setData] = useState({});

  const getSta = async () => {
    try {
      const request = await axios.get(`${CTX.url}/vendor/overview`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${CTX.token}`,
        },
      });
      setLoading(false);
      setData(request.data.data)
      console.log("request.data => ", request.data)
      CTX.setStats(request.data.data)

    } catch (error) {
      setLoading(false);
      // getSta()
      // setMsg('Invalid login details');
      console.log(error);
    }
  };


  useEffect(() => {
    getSta()
  }, [])


  return {loadingStatistics, data, getSta};
};


// HD2020/07289/1/CS