import React, {useContext, useState, useRef} from 'react';

import {Modal} from '../../component/modal/modal';
import Input from '../../component/input/input';
import Button from '../../component/button/button';
import Classes from './withdrawalSettings.module.css';
import {MainContext} from '../../App';
import axios from 'axios';
import {GetUserDetails} from '../../helper/getUserHelper';

const ChangePass = ({
  show,
  close,
  showAgent,
  closeAgent,
  showImage,
  closeImage,
}) => {
  const [inputs, setInputs] = useState({});
  const {getUser} = GetUserDetails();
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const imgRef = useRef();
  const CTX = useContext(MainContext);

  const onSubmitPasswordHandler = async (e) => {
    e.preventDefault();
    console.log(inputs);
    if (inputs.newPassword !== inputs.conPassword) {
      setTimeout(() => {
        setMsg('');
      }, 5000);
      setMsg('Passwords do not match');
      return;
    }
    setLoading(true);
    try {
      const request = await axios({
        url: `${CTX.url}/vendor/setting/change/password`,
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${CTX.token}`,
        },
        data: {
          old_password: inputs.password,
          new_password: inputs.newPassword,
          confirm_password: inputs.conPassword,
        },
      });
      console.log('request.data', request.data);
      setLoading(false);
      setTimeout(() => {
        setMsg('');
      }, 4000);
      setMsg('Details saved successfully');
    } catch (error) {
      setLoading(false);
      setTimeout(() => {
        setMsg('');
      }, 4000);
      setMsg('Invalid details');
      console.log(error);
    }
  };

  // uploading the profile image
  const onBlurImageHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      setLoading(false);
      var formData = new FormData();
      formData.append('store_logo', e.target.files[0]);
      const request = await axios({
        url: `${CTX.url}/vendor/setting/store/logo/update`,
        data: formData,
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${CTX.token}`,
        },
      });
      const sad = getUser();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        show={show}
        close={() => {
          close();
          setInputs({password: '', conPassword: '', newPassword: ''});
        }}
      >
        <div className={Classes.headerSection}>
          <strong>Change Password</strong>
        </div>
        {msg.length > 0 && (
          <div
            className={Classes.msgAlert}
            style={{textAlign: 'center', marginBottom: '40px'}}
          >
            <p style={{margin: '0px 0px'}} className={Classes.msgItself}>
              {msg}
            </p>
          </div>
        )}
        <form onSubmit={onSubmitPasswordHandler}>
          <div
            style={{paddingBottom: '0px'}}
            className={Classes.subHeaderSection}
          >
            <Input
              required
              placeholder="Existing Password"
              label="Existing Password"
              type="input"
              value={inputs?.password}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  password: e.target.value,
                })
              }
              inputType="password"
            />

            <div style={{display: 'flex'}}>
              <Input
                required
                placeholder="New Password"
                label="New Password"
                type="input"
                inputType="password"
                value={inputs?.newPassword}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    newPassword: e.target.value,
                  })
                }
              />
              <div style={{width: '30px'}}> </div>
              <Input
                required
                placeholder="Confirm Password"
                label="Confirm Password"
                type="input"
                inputType="password"
                value={inputs?.conPassword}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    conPassword: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className={Classes.bottomBorder}>
            <div style={{marginLeft: 'auto', display: 'flex'}}>
              <Button
                bgColor="#ff0166"
                text="Save Password"
                loading={loading}
                showLoading={false}
              />
              <div style={{width: '20px'}}> </div>
              {/* <Button bgColor="#6c757d" text="Cancel" /> */}
              <Button
                text="Cancel"
                onClick={() => {
                  close();
                  setInputs({password: '', conPassword: '', newPassword: ''});
                }}
                style={{
                  marginLeft: '20px',
                  backgroundColor: '#fff',
                  color: '#ff0066',
                }}
              />
            </div>
          </div>
        </form>
      </Modal>

      <Modal show={showAgent} close={closeAgent}>
        <div className={Classes.headerSection}>
          <strong>Request Agent Change</strong>
        </div>
        <div
          style={{paddingBottom: '0px'}}
          className={Classes.subHeaderSection}
        >
          <Input
            txtPlaceholder=" "
            label="Add a reason for the request:"
            type="textarea"
            inputType="password"
          />
        </div>

        <div className={Classes.bottomBorder}>
          <div style={{marginLeft: 'auto', display: 'flex'}}>
            {/* <Button bgColor="#ff0166" text="Save Password" /> */}
            <div style={{width: '20px'}}> </div>
            <Button bgColor="#6c757d" text="Yes, Request" />
          </div>
        </div>
      </Modal>

      <Modal show={showImage} close={closeImage}>
        <div className={Classes.headerSection}>
          <strong>Change Profile Image</strong>
        </div>
        <form onSubmit={onBlurImageHandler}>
          <div
            style={{paddingBottom: '0px'}}
            className={Classes.subHeaderSection}
          >
            <Input
              txtPlaceholder=" "
              label="Select an image"
              type="input"
              accept="image/*"
              required
              inputType="file"
              ref={imgRef}
              // onBlur={}
            />
          </div>

          <div className={Classes.bottomBorder}>
            <div style={{marginLeft: 'auto', display: 'flex'}}>
              <Button
                bgColor="#6c757d"
                text="Change"
                style={{height: '34px'}}
                loading={loading}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ChangePass;
