import React, {useState, useEffect, useRef, useContext} from 'react';
import EachOrder from './eachOrder';
import {useParams} from "react-router-dom"
import { MainContext } from '../../App';

const EventEachOrder = ({itemsPerPage="8"}) => {
  const orderParams = useParams()
  const [data, setData] = useState({
    order_status: false,
    name: 'Sopsop Deliveries',
    phone: '08106046040',
  });
const CTX = useContext(MainContext)
  const [orders, setOrders] = useState({order: []});
  const [loading, setLoading] = useState(false);
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const refReactPaginate = useRef();

  const getAllOrder = async () => {
    try {
      
    setLoading(true);
    const fetched = await fetch(`${CTX.url}/vendor/orders`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${CTX.token}`,
      },
    });
    const json = await fetched.json();
    setLoading(false);

    setOrders(json.data)

  } catch (error) {
      console.log(error)
  }
  };

  useEffect(() => {
    getAllOrder();
  }, [orderParams.id]);

  useEffect(() => {
    if (orders.length < 1) return;
    // Fetch items from another resources.carts
    const endOffset = itemOffset + itemsPerPage;
    //  console.log(`Loading carts from ${itemOffset} to ${endOffset}`);
    const allContent = orders.slice(itemOffset, endOffset);
    const eachPageItem = allContent.splice(0, itemsPerPage);
    //  setCurrentItems(items.slice(itemOffset, endOffset));
    setCurrentItems(eachPageItem);
    setPageCount(Math.ceil(orders.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, orders]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setPageNum(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % orders.length;
    setItemOffset(newOffset);
  };

  return <EachOrder data={data}
  currentItems={currentItems}
  pageCount={pageCount}
  pageNum={pageNum}
  loading={loading}
  handlePageClick={handlePageClick}
  refReactPaginate={refReactPaginate}
  orders={orders}
  />;
};

export default EventEachOrder;
