import React, {Suspense, useState, useContext} from 'react';
import {Helmet} from 'react-helmet';
import {AiOutlineLock, AiOutlineRight} from 'react-icons/ai';

import Card from '../../component/card/card';
import Button from '../../component/button/button';
import Classes from './withdrawalSettings.module.css';
import RightSide from './rightSide';
import ChangePass from './changePass';
import {MainContext} from '../../App';
import Sidebar from '../../component/layout/sidebar/sidebar';
import LazyLoading from '../../component/lazyLoading/lazyLoading';
import {
  EachPageHeader,
  PageHeader,
} from '../../component/layout/eachPageHeader/eachPageHeader';

const WithdrawalSettings = () => {
  const CTX = useContext(MainContext);
  const [password, setPassword] = useState(false);
  const [agent, setAgent] = useState(false);
  const [showImage, setShowImage] = useState(false);
  

  return (
    <>
      {/* change password modal */}
      <ChangePass
        show={password}
        close={() => setPassword(false)}
        showAgent={agent}
        showImage={showImage}
        closeImage={() => setShowImage(false)}
        closeAgent={() => setAgent(false)}
      />

      <Helmet>
        <title>Settings - Passward Vendor </title>
        <meta name="og:title" content="Settings - Passward Vendor" />
        <meta name="og:description" content="Settings - Passward Vendor" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <div className={Classes.flexSection}>
        <Sidebar />
        <div
          onClick={CTX.removeSideBar}
          style={{
            padding: '0px 20px',
            boxSizing: 'border-box',
            width: '100%',
            filter: 'blur(var(--body-blur,0px))',
          }}
        >
          <Suspense fallback={<LazyLoading />}>
            {/* nav section here is just the top header side */}
            <EachPageHeader first="Home" second="Account" third="Settings" />
            <PageHeader name="Settings" />
            <div className={Classes.gridTwoFiles}>
              <div className={Classes.firstCardCover}>
                <div className={Classes.pageHeader}>Store Settings</div>
                <Card>
                  <div className={Classes.paddingCard}>
                    <img
                      className={Classes.avatar}
                      src={CTX.user.photo}
                      alt="avatar"
                    />
                    <strong className={Classes.profileName}>
                      {CTX.user.first_name} {CTX.user.last_name}
                    </strong>

                    <div style={{width: '170px', margin: 'auto'}}>
                      <Button
                        bgColor="#f1556c"
                        style={{backgroundColor: '#000033', border: 'none'}}
                        text="Change Password"
                        onClick={() => setPassword(true)}
                        svg={<AiOutlineLock style={{marginRight: '8px'}} />}
                      />
                    </div>

                    <div className={Classes.textLeft}>
                      <p className="text-muted mb-2 font-13">
                        <strong>Full Name:</strong>{' '}
                        <span
                          className={Classes.ml_2}
                          style={{textTransform: 'capitalize'}}
                        >
                          {CTX.user.first_name} {CTX.user.last_name}
                        </span>
                      </p>

                      <p className="text-muted mb-2 font-13">
                        <strong>Phone Number:</strong>
                        <span className={Classes.ml_2}>{CTX.user.phone}</span>
                      </p>

                      <p className="text-muted mb-2 font-13">
                        <strong>Email Address:</strong>{' '}
                        <span className={Classes.ml_2}>{CTX.user.email}</span>
                      </p>

                      {/* <p className="text-muted mb-1 font-13">
                        <strong>State:</strong>{' '}
                        <span className={Classes.ml_2}>{CTX.user.state}</span>
                      </p> */}
                    </div>
                  </div>
                </Card>
                <Card>
                  <div className={Classes.paddingCard}>
                    <img
                      className={Classes.avatar}
                      src={require('../../asset/avatar.png')}
                      alt="avatar"
                    />
                    <strong className={Classes.profileName}>
                      AKPAN UYO-OBONG
                    </strong>

                    <div style={{width: '170px', margin: 'auto'}}>
                      <Button
                        onClick={ () => setShowImage(!showImage)}
                        bgColor="#f1556c"
                        style={{
                          margin: '10px auto auto auto',
                          backgroundColor: '#000033',
                          border: 'none',
                        }}
                        text="Change Profile Image"
                      />
                    </div>

                    <div className={Classes.textLeft}>
                      <p className="text-muted mb-2 font-13">
                        <strong>Agent Name:</strong>{' '}
                        <span className={Classes.ml_2}>AKPAN UYO-OBONG</span>
                      </p>

                      <p className="text-muted mb-2 font-13">
                        <strong>Agent Phone:</strong>
                        <span className={Classes.ml_2}>+2347067172511</span>
                      </p>

                      <p className="text-muted mb-2 font-13">
                        <strong>Agent Email:</strong>{' '}
                        <span className={Classes.ml_2}>
                          excellentloaded@gmail.com
                        </span>
                      </p>
                    </div>
                    <Button
                      onClick={() => setAgent(!agent)}
                      bgColor="#f1556c"
                      style={{
                        margin: 'auto',
                        marginTop: '10px',
                        backgroundColor: 'inherit',
                        color: '#ff0066',
                      }}
                      text="Change Agent"
                    />
                  </div>
                </Card>
              </div>

              <RightSide />
            </div>
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default WithdrawalSettings;
