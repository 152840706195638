import React, {useContext, useEffect, useState} from 'react';

import Input from '../../component/input/input';
import {Modal} from '../../component/modal/modal';
import Classes from './subscription.module.css';
import Button from '../../component/button/button';
import {MainContext} from '../../App';
import {Link} from 'react-router-dom';

const ModalSection = ({sendMessageHandler, showModal, setShowModal}) => {
  const CTX = useContext(MainContext);
  const [updateDetails, setUpdateDetails] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  //   useEffect(() => {
  //     if (!CTX.user.bank) {
  //       setUpdateDetails(true);
  //     } else {
  //       setUpdateDetails(false);
  //     }
  //   });

  const formOnSubmitHandler = (e) => {
    e.preventDefault();
    setTimeout(() => {
      setLoading(false);
      setConfirm(true);
    }, 1000);
    setLoading(true);
  };

  return (
    <Modal
      top="50%"
      show={showModal}
      showClose={confirm && false}
      close={() => setShowModal(!showModal)}
    >
      <h3 className={Classes.confirmRemoval}>Change Subscription Plan</h3>

      <form className={Classes.cover} onSubmit={formOnSubmitHandler}>
        <Input
          label="Plan"
          // style={{paddingLeft: '60px'}}
          required
          type="select"
          options={['Select Plan']}
        />
        <Input
          label="Plan Type"
          // style={{paddingLeft: '60px'}}
          required
          type="select"
          options={['Select Duration']}
        />
        <div className={Classes.cover}>
          <div className={Classes.updateDetailsCover}>
            <div style={{ textAlign: 'center'}}>
            ₦0 will be charge from your account.
            </div>
          </div>
        </div>

        <div className={Classes.flexRight}>
          <Button
            text="Upgrade"
            style={{width: '130px'}}
            loading={loading}
            loading={loading}
            showLoading={false}
            onClick={sendMessageHandler}
          />
          <Button
            text="Cancel"
            onClick={() => setShowModal(false)}
            style={{
              width: '130px',
              marginLeft: '20px',
              backgroundColor: '#fff',
              color: '#ff0066',
            }}
          />
        </div>
      </form>
    </Modal>
  );
};

export default ModalSection;
