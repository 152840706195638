import React from 'react';
import Classes from './dashboard.module.css';

const RecentOrder = () => {
  const orders = [
    {
      id: 78576789,
      quantity: 2,
      date: '12/02/2022',
      status: false,
      total: 'N5000',
    },
    {
      id: 87677800,
      quantity: 4,
      date: '12/02/2022',
      status: true,
      total: 'N5000',
    },
    {
      id: 54765867,
      quantity: 6,
      date: '12/02/2022',
      status: true,
      total: 'N5000',
    },
    {
      id: 66578799,
      quantity: 1,
      date: '12/02/2022',
      status: false,
      total: 'N5000',
    },
  ];

  const mappedOrders = orders.map((v) => (
    <div key={v.id} className={Classes.gridBodySection}>
      <span>
        <strong className={Classes.smallStrong}>Order ID:</strong>
        <small>{v.id}</small>
      </span>
      <small>
        {v.status ? (
          <div className={Classes.pending}>Pending</div>
        ) : (
          <div className={Classes.completed}>Completed</div>
        )}
      </small>
      <span className={Classes.centerSpan}>
        <strong className={Classes.smallStrong}>Product Quantity</strong>
        <small>{v.quantity}</small>
      </span>
      <strong className={Classes.smallStrong}></strong>
      <small>{v.date}</small>
      <small>{v.total}</small>
    </div>
  ));

  return (
    <div className={Classes.RecentOrderCover}>
      <div style={{position: 'relative'}} className={Classes.headerSection}>
        <strong>Recent Orders</strong>
        <select style={{width: '100px'}}>
          <option>February</option>
          <option>March</option>
          <option>April</option>
          <option>May</option>
        </select>
      </div>
      <div className={Classes.gridHeaderSection}>
        <strong>Order ID</strong>
        <strong>Status</strong>
        <strong>Quantity</strong>
        <strong>Date</strong>
        <strong>Total</strong>
      </div>
      {mappedOrders}
    </div>
  );
};

export default RecentOrder;
