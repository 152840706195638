import React, {useEffect, useState, useContext, useRef} from 'react';
import Classes from './withdrawalSettings.module.css';
import {AiOutlineHome} from 'react-icons/ai';
import Input from '../../component/input/input';
import Card from '../../component/card/card';
import Button from '../../component/button/button';
import {States} from '../../data/stateJson';
import axios from 'axios';
import {MainContext} from '../../App';
import {GetUserDetails} from '../../helper/getUserHelper';
import {ReactComponent as FB} from '../../asset/facebook.svg';
import {ReactComponent as IG} from '../../asset/instagram.svg';
import {ReactComponent as TW} from '../../asset/twitter.svg';
import {ReactComponent as LI} from '../../asset/linkedin.svg';
import {useLocation} from 'react-router-dom';
import Withdrawal from './withdrawal';
import { Link } from 'react-router-dom';

const RightSide = () => {
  const [toggle, setToggler] = useState(false);
  const location = useLocation();
  const {getUser} = GetUserDetails();
  const CTX = useContext(MainContext);
  const [msg, setMsg] = useState('');
  const [state, setState] = useState('');
  const imgRef = useRef()
  const [inputs, setInputs] = useState(
    {
      ...CTX.user,
      social_detail: {
        fb_url: '',
        linkedin_url: '',
        twitter_url: '',
        instagram_url: '',
        website: '',
      },
    } || {}
  );
  const [loading, setLoading] = useState(false);

  const onSubmitDetailsHandler = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      var formData = new FormData();
      formData.append('profile_image', imgRef.current?.target?.files[0]);
      formData.append('first_name', inputs.first_name);
      formData.append('last_name', inputs.last_name);
      formData.append('store_name', inputs?.store_detail?.name);
      formData.append('state', state);
      formData.append('phone_number', inputs?.phone);
      formData.append('description', inputs?.store_detail?.description);
      formData.append('address', inputs?.store_detail?.address);
      formData.append('website', inputs?.social_detail?.website);
      formData.append('fb_url', inputs?.social_detail?.fb_url);
      formData.append('linkedin_url', inputs?.social_detail?.linkedin_url);
      formData.append('twitter_url', inputs?.social_detail?.twitter_url);
      formData.append('instagram_url', inputs?.social_detail?.instagram_url);
        const request = await axios({
        url: `${CTX.url}/vendor/setting/profile`,
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${CTX.token}`,
        },
        data: formData,
      });
      // console.log('request', request);
      setLoading(false);
      // setTimeout(() => {
      //   setMsg('');
      // }, 4000);
      setMsg('Details saved successfully');
      const sad = getUser();
    } catch (error) {
      setLoading(false);
      // setTimeout(() => {
      //   setMsg('');
      // }, 4000);
      setMsg('Invalid details');
      console.log(error);
    }
  };

  const onChangeHandlerForStores = (name, e) => {
    const spread = {...inputs};
    spread.store_detail[name] = e.target.value;
    setInputs(spread);
  };

  const onChangeHandlerForWebsites = (name, e) => {
    const spread = {...inputs};
    spread.social_detail[name] = e.target.value;
    setInputs(spread);
  };

  useEffect(() => {
    if (location.search.split('=')[1] === 'withdrawal') {
      setToggler(true);
    } else {
      setToggler(false);
    }
    // window.location.search.split('=')[0] === '?token'
  }, [location]);


  return (
    <div>
      <div className={Classes.pageHeader}>Account/Withdrawal Settings</div>
      <Card style={{height: 'max-content'}}>
        <div className={Classes.paddingCard}>
          <div className={Classes.flexTwo}>
          <Link to="/settings?type=account" style={{textDecoration: "none", marginRight: "50px"}} >
            <div
              style={{
                backgroundColor: !toggle ? '#ff0166' : '#f7f7f7',
                color: !toggle ? '#fff' : '#6c757d',
              }}
              onClick={() => setToggler(false)}
              className={Classes.twoRoutes}
            >
              Account
            </div>
            </Link>
            <Link to="/settings?type=withdrawal" style={{textDecoration: "none"}} >
            <div
              style={{
                backgroundColor: toggle ? '#ff0166' : '#f7f7f7',
                color: toggle ? '#fff' : '#6c757d',
              }}
              onClick={() => setToggler(true)}
              className={Classes.twoRoutes}
            >
              Withdrawal
            </div>
            </Link>
          </div>

          {!toggle && (
            <form onSubmit={onSubmitDetailsHandler}>
               {msg.length > 0 && (
            <div
              className={Classes.msgAlert}
              style={{textAlign: 'center', marginBottom: '40px'}}
            >
              <p style={{margin: '0px 0px'}} className={Classes.msgItself}>
                {msg}
              </p>
            </div>
          )}
              <div className={Classes.personalInfoCover}>
                {/* <AiOutlineUser style={{marginRight: '10px', lineHeight: '1'}} /> */}
                <span style={{lineHeight: '1'}}>PERSONAL INFORMATION</span>
              </div>
              <div className={Classes.gridTwo}>
                <Input
                  type="input"
                  value={inputs?.first_name}
                  onChange={(e) =>
                    setInputs({...inputs, first_name: e.target.value})
                  }
                  label="First Name"
                />
                <Input
                  type="input"
                  value={inputs?.last_name}
                  onChange={(e) =>
                    setInputs({...inputs, last_name: e.target.value})
                  }
                  label="Last Name"
                />
              </div>
              <div className={Classes.gridTwo}>
                <Input
                  type="input"
                  inputType="email"
                  label="Email Address"
                  value={CTX.user.email}
                  readOnly={true}
                  msg="Registered email address can not be modified."
                />
                <Input
                  value={inputs?.phone}
                  onChange={(e) =>
                    setInputs({...inputs, phone: e.target.value})
                  }
                  type="input"
                  label="Phone Number"
                />
              </div>

              <div className={Classes.personalInfoCover}>
                <AiOutlineHome style={{marginRight: '10px', lineHeight: '1'}} />
                <span style={{lineHeight: '1'}}>STORE INFORMATION</span>
              </div>

              <div className={Classes.gridTwo}>
                <Input
                  value={inputs?.store_detail?.name}
                  onChange={onChangeHandlerForStores.bind(this, 'name')}
                  type="input"
                  inputType="text"
                  label="Store Name"
                />
                <Input
                  type="select"
                  // required
                  selected={inputs?.state}
                  options={States.map((v) => {
                    return v.name;
                  })}
                  label="Store Location"
                  onChange={e => setState(e.target.value)}
                />
              </div>

              <div className={Classes.gridTwo}>
                <Input
                  value={inputs?.store_detail?.address}
                  onChange={onChangeHandlerForStores.bind(this, 'address')}
                  type="input"
                  inputType="text"
                  label="Address"
                />
                <Input
                  type="input"
                  inputType="file"
                  label="Store Image"
                  accept="image/png, image/gif, image/jpeg"
                  ref={imgRef}
                  // onBlur={onBlurImageHandler}
                />
              </div>
              <Input
                type="textarea"
                label="Description"
                value={inputs?.store_detail?.description}
                onChange={onChangeHandlerForStores.bind(this, 'description')}
              />
              <div className={Classes.personalInfoCover}>
                <span style={{lineHeight: '1'}}> SOCIAL MEDIA</span>
              </div>

              <Input
                type="input"
                label="Website"
                value={inputs?.social_detail?.website}
                onChange={onChangeHandlerForWebsites.bind(this, 'website')}
                placeholder="Website URL"
              />
              <div className={Classes.gridTwo}>
                <div className={Classes.amountCover}>
                  <div className={Classes.nairaCover}>
                    <FB />
                  </div>
                  <Input
                    type="input"
                    //
                    value={inputs?.social_detail?.fb_url}
                    onChange={onChangeHandlerForWebsites.bind(this, 'fb_url')}
                    label="Facebook"
                    style={{paddingLeft: '60px'}}
                    marb={true}
                    placeholder="Facebook URL"
                  />
                </div>
                <div className={Classes.amountCover}>
                  <div className={Classes.nairaCover}>
                    <TW />
                  </div>
                  <Input
                    type="input"
                    label="Twitter"
                    value={inputs?.social_detail?.twitter_url}
                    onChange={onChangeHandlerForWebsites.bind(
                      this,
                      'twitter_url'
                    )}
                    style={{paddingLeft: '60px'}}
                    marb={true}
                    placeholder="Twitter URL"
                  />{' '}
                </div>

                <div className={Classes.amountCover}>
                  <div className={Classes.nairaCover}>
                    <IG />
                  </div>
                  <Input
                    type="input"
                    style={{paddingLeft: '60px'}}
                    marb={true}
                    label="Instagram"
                    value={inputs?.social_detail?.instagram_url}
                    onChange={onChangeHandlerForWebsites.bind(
                      this,
                      'instagram_url'
                    )}
                    placeholder="Instagram URL"
                  />
                </div>

                <div className={Classes.amountCover}>
                  <div className={Classes.nairaCover}>
                    <LI />
                  </div>
                  <Input
                    style={{paddingLeft: '60px'}}
                    marb={true}
                    type="input"
                    label="Linkedin"
                    placeholder="Linkedin URL"
                    value={inputs?.social_detail?.linkedin_url}
                    onChange={onChangeHandlerForWebsites.bind(
                      this,
                      'linkedin_url'
                    )}
                  />
                </div>
              </div>

              <Button
                style={{marginTop: '30px', height: "38px"}}
                text="Save Changes"
                bgColor="#1abc9c"
                loading={loading}
              />
            </form>
          )}

          {toggle && <Withdrawal />}
        </div>
      </Card>
    </div>
  );
};

export default RightSide;
