import React, {useState, useEffect, useRef, useContext} from 'react';
import {MainContext} from '../../App';
import Products from './products';

const EventProducts = ({itemsPerPage = '10'}) => {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  // const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [category, setCategory] = useState([]);
  const [msg, setMsg] = useState('');
  const [searchInput, setSearchInput] = useState('');

  const refReactPaginate = useRef();
  const CTX = useContext(MainContext);

  const getAllProducts = async () => {
    setLoading(true);
    const fetched = await fetch(`${CTX.url}/vendor/products`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${CTX.token}`,
      },
    });
    const json = await fetched.json();

    setLoading(false);
    setProducts(json.data);
  };

  const getAllCategory = async () => {
    try {
      const fetched = await fetch(`${CTX.url}/misc/category`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${CTX.token}`,
        },
      });
      const json = await fetched.json();
      setCategory(json.results);
    } catch (error) {
      console.log(error);
      console.log(error);
    }
    // setLoading(false);
    // setProducts(json.data);
  };

  useEffect(() => {
    getAllCategory();
    getAllProducts();
  }, []);

  useEffect(() => {
    if (products.length < 1) return;
    // Fetch items from another resources.carts
    const endOffset = itemOffset + itemsPerPage;
    //  console.log(`Loading carts from ${itemOffset} to ${endOffset}`);
    const allContent = products.slice(itemOffset, endOffset);
    const eachPageItem = allContent.splice(0, itemsPerPage);
    //  setCurrentItems(items.slice(itemOffset, endOffset));
    setCurrentItems(eachPageItem);
    setPageCount(Math.ceil(products.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, products]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setPageNum(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % products.length;
    setItemOffset(newOffset);
  };

  // deleting any perticular product with the id
  const deleteProductHandler = (_id, e) => {
    setLoading(true);
    fetch(`${CTX.url}/vendor/product/delete/${_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${CTX.token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        getAllProducts();
      })
      .catch((err) => {
        console.log('err => ', err);
        setLoading(false);
        setMsg('Check Your Internet');
      });
  };

  return (
    <Products
      showModal={showModal}
      setShowModal={setShowModal}
      loading={loading}
      handlePageClick={handlePageClick}
      pageCount={pageCount}
      currentItems={currentItems}
      products={products}
      pageNum={pageNum}
      refReactPaginate={refReactPaginate}
      isEdit={isEdit}
      setIsEdit={setIsEdit}
      category={category}
      reGetProducts={getAllProducts}
      deleteProduct={deleteProductHandler}
      msg={msg}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
    />
  );
};

export default EventProducts;
