import React, {useContext} from 'react';

import Classes from './layout.module.css';
import Nav from './nav/nav';
// import Sidebar from './sidebar/sidebar';
import Dashboard from '../../pages/dashboard/dashboard';
import {Navigate, Route, Routes} from 'react-router-dom';
import {MainContext} from '../../App';
import Customer from '../../pages/customer/eventCustomer'
import Products from '../../pages/products/eventProducts';
import Orders from '../../pages/orders/eventOrders';
import EachOrder from '../../pages/eachOrder/eventEachOrder';
import Wallet from '../../pages/wallet/eventWallet';
import Subscription from '../../pages/subscription/eventSubscription';
import Settings from '../../pages/settings/settings';

const Layout = () => {
  const removeSideBar = () => {
    document.body.removeAttribute('data-side-navigation-mode');
    document.body.classList.remove('fade-effect');
  };

  const CTX = useContext(MainContext);

  return (
    <div className={Classes.allLayoutCover}>
      <Nav />
      {/* <div onClick={removeSideBar} className={Classes.premium}>
      <small>
        Premium Subscription coming Soon...experience unlimited...
      </small>
    </div> */}
      <div className={Classes.layoutCover}>
        <div onClick={removeSideBar} style={{height: '20px'}}></div>
        <div className={Classes.splitSidebarCover}>
          <div className={Classes.bodySectionSection}>
            {/* // import all pages */}
            <Routes>
            {CTX.token && (
                <Route
                  path="/login"
                  exact
                  element={<Navigate replace to={'/dashboard'} />}
                />
              )}
              {!CTX.token && (
                <Route
                  path="/"
                  exact
                  element={<Navigate replace to={'/login'} />}
                />
              )}
              {CTX.token && (
                <Route
                  path="/"
                  exact
                  element={<Navigate replace to={'/dashboard'} />}
                />
              )}
              {CTX.token && <Route path="/dashboard" element={<Dashboard />} />}
              {CTX.token && <Route path="/customers" element={<Customer />} />}
              {CTX.token && <Route path="/products" element={<Products />} />}
              {CTX.token && <Route path="/my-orders" element={<Orders />} />}
              {CTX.token && <Route path="/my-orders/:id" element={<EachOrder />} />}
              {CTX.token && <Route path="/wallet" element={<Wallet />} />}
              {CTX.token && <Route path="/subscription" element={<Subscription />} />}
              {CTX.token && <Route path="/settings" exact element={<Settings />} />}
              
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Layout;
