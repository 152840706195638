import React, {useContext, useEffect, useRef, useState} from 'react';
import Input from '../../component/input/input';
import {Modal} from '../../component/modal/modal';
import Classes from './products.module.css';
import Button from '../../component/button/button';
import {MainContext} from '../../App';
import axios from 'axios';

const ModalSection = ({
  showModal,
  setShowModal,
  isEdit,
  clicked,
  clearClicked,
  reGetProducts,
  category
}) => {
  const [msg, setMsg] = useState('');
  const CTX = useContext(MainContext);
  // const imageRef = useRef();
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({});
  const [selected, setSelected] = useState("")

  const onSubmitHandler = async (e) => {
    
    try {
      e.preventDefault();
      setLoading(true);
      var formData = new FormData();
      formData.append('thumbnail', inputs.file[0]);

      formData.append('title', inputs.title);
      formData.append('price', inputs.price);
      formData.append('discount_price', inputs.discount_price);
      formData.append('category', category.filter(v => v.name === selected)[0].pk);
      formData.append('tag', 'new');
      formData.append('short_description', inputs.short_description);
      formData.append('description', inputs.description);
      formData.append('inventory', inputs.inventory);

      let url = isEdit
        ? `${CTX.url}/vendor/product/put/${inputs.pk}`
        : `${CTX.url}/vendor/product/create`;

      const request = await axios({
        url,
        method: isEdit ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${CTX.token}`,
        },
        data: formData,
      });
      closeModal();
      setLoading(false);
      reGetProducts()
      // setProducts(json.data);
    } catch (error) {
      setMsg('Invalid Details');
      setLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setMsg('');
    setInputs({
      title: '',
      price: '',
      discount_price: '',
      category: '',
      tag: '',
      short_description: '',
      description: '',
      inventory: '',
    });
    clearClicked({});
  };

  useEffect(() => {
    setInputs(clicked);
  }, [clicked]);

  // console.log("selected => ", selected);
  // console.log("selected => ", category.filter(v => v.name === selected)[0].pk);
  // console.log("selected => ", selected);

  return (
    <Modal top="50%" show={showModal} close={closeModal}>
      <h3 className={Classes.confirmRemoval}>
        {isEdit ? 'Edit Product' : 'Add Product'}
      </h3>

      <div className={Classes.scrollDeep}>
        <form onSubmit={onSubmitHandler}>
          {msg.length > 0 && (
            <div
              className={Classes.msgAlert}
              style={{textAlign: 'center', marginBottom: '40px'}}
            >
              <p style={{margin: '0px 0px'}} className={Classes.msgItself}>
                {msg}
              </p>
            </div>
          )}
          <div className={Classes.gridTwo}>
            <Input
              required
              label="Product Name"
              type="input"
              placeholder="Product Name"
              value={inputs?.title}
              onChange={(e) => setInputs({...inputs, title: e.target.value})}
            />
            <Input
              required
              label="Product Category"
              type="select"
              options={category.map(v => {
                return v.name
              })}
              onChange={e => setSelected(e.target.value)}
            />
            <Input
              required
              label="Product Price"
              type="input"
              placeholder="Product Price"
              value={inputs?.price}
              inputType="number"
              onChange={(e) => setInputs({...inputs, price: e.target.value})}
            />
            <Input
              required
              label="Discounted Price"
              type="input"
              inputType="number"
              placeholder="Discounted Price"
              value={inputs?.discount_price}
              onChange={(e) =>
                setInputs({...inputs, discount_price: e.target.value})
              }
            />
            <Input
              type="input"
              style={{backgroundColor: '#000033'}}
              inputType="file"
              onChange={(e) => setInputs({...inputs, file: e.target.files})}
              label="Thumbnail"
              required
            />
            <Input
              required
              label="Inventory"
              type="input"
              placeholder="Inventory"
              inputType="number"
              value={inputs?.inventory}
              onChange={(e) =>
                setInputs({...inputs, inventory: e.target.value})
              }
            />
          </div>
          <div className={Classes.gridOne}>
            <Input
              required
              label="Product Feature"
              type="textarea"
              txtPlaceholder="Product feature"
              value={inputs?.short_description}
              onChange={(e) =>
                setInputs({...inputs, short_description: e.target.value})
              }
            />
            <Input
              required
              label="Description"
              type="textarea"
              txtPlaceholder="Describe your product"
              rows="4"
              value={inputs?.description}
              onChange={(e) =>
                setInputs({...inputs, description: e.target.value})
              }
            />
          </div>
          <div className={Classes.flexRight}>
            <Button
              text={isEdit ? 'Edit Product' : 'Add Product'}
              loading={loading}
              // showLoading={false}
              style={{height: '38px'}}
            />
            <Button
              text="Cancel"
              onClick={closeModal}
              style={{
                marginLeft: '20px',
                backgroundColor: '#fff',
                color: '#ff0066',
              }}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ModalSection;
