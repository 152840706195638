import Layout from './component/layout/layout';
import Login from './pages/login/eventLogin';
import React, {useState} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

function App() {
  const url = 'https://api.passward.ng/api/v1';
  const [token, setToken] = useState(sessionStorage.getItem('v_tk') || '');
  const [stats, setStats] = useState(JSON.parse(sessionStorage.getItem('stats')) ||{})
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem('user')) || {}
  );

  const openLogin = () => {
    document.body.setAttribute('data-side-login-mode', 'open');
  };

  const closeLogin = () => {
    document.body.removeAttribute('data-side-login-mode', 'open');
  };

  const removeSideBar = () => {
    document.body.removeAttribute('data-side-navigation-mode');
    document.body.classList.remove('fade-effect');
  };


  const checkLogin = () => {
    if (token.length < 3) {
      openLogin();
    } else {
      return;
    }
  };


  const setStatsHandler = (_payload) => {
    sessionStorage.setItem('stats', JSON.stringify(_payload))
    setStats(_payload)
  }

  const setTokenHandler = (_token) => {
    setToken(_token);
    closeLogin();
    sessionStorage.setItem('v_tk', _token);
  };

  const setUserHandler = (_user) => {
    setUser(_user);
    sessionStorage.setItem('user', JSON.stringify(_user));
  };

  const logoutHandler = () => {
    sessionStorage.clear();
    setToken('');
    setUser({});
  };

  
  return (
    <MainContext.Provider
      value={{
        url,
        openLogin,
        closeLogin,
        checkLogin,
        token,
        removeSideBar,
        user,
        setToken: setTokenHandler,
        setUser: setUserHandler,
        logout: logoutHandler,
        setStats: setStatsHandler,
        stats,
      }}
    >
      <Routes>
      {!token && (
                <Route
                  path="/"
                  exact
                  element={<Navigate replace to={'/login'} />}
                />
              )}
              {token && (
                <Route
                  path="/login"
                  exact
                  element={<Navigate replace to={'/dashboard'} />}
                />
              )}

        {!token && <Route path="/login" element={<Login />} />}
        {!token && (
                <Route
                  path="/*"
                  exact
                  element={<Navigate replace to={'/login'} />}
                />
              )}
      </Routes>

      {token && <Layout />}
    </MainContext.Provider>
  );
}

export default App;

export const MainContext = React.createContext({
  url: null,
  user: null,
  token: null,
  openLogin: () => {},
  closeLogin: () => {},
  checkLogin: () => {},
  setToken: () => {},
  removeSideBar: () => {},
  logout: () => {},
  setUser: () => {},
  setStats: () => {},
  stats: null,
});



// vendor settings website url
// vendor settings state is not updating even after fetch request
// misc category is not working...
// product name is unique...
// their is no page for  list messages sent to customers incase the vendor want to resend it.
// overview routes
// with sidebar routes
// customer no no. of orders, revenue and notification
// cha




// Under Vendor Settings, when editing the state, it doesn't update!
// When a vendor is adding the product, product name is unique!
// Overview route!
// no route to get all agent Incase when a vendor wants to choose another agent!
