import React, {useContext, useEffect, useState} from 'react';

import Input from '../../component/input/input';
import {Modal} from '../../component/modal/modal';
import Classes from './wallet.module.css';
import Button from '../../component/button/button';
import {MainContext} from '../../App';
import {Link} from 'react-router-dom';

const ModalSection = ({sendMessageHandler, showModal, setShowModal}) => {
  const CTX = useContext(MainContext);
  const [updateDetails, setUpdateDetails] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  //   useEffect(() => {
  //     if (!CTX.user.bank) {
  //       setUpdateDetails(true);
  //     } else {
  //       setUpdateDetails(false);
  //     }
  //   });

  const formOnSubmitHandler = (e) => {
    e.preventDefault();
    setTimeout(() => {
      setLoading(false);
      setConfirm(true);
    }, 1000);
    setLoading(true);
  };

  return (
    <Modal
      top="50%"
      show={showModal}
      showClose={confirm && false}
      close={() => setShowModal(!showModal)}
    >
      <h3 className={Classes.confirmRemoval}>
        {confirm ? 'Confirm Withdrawal' : 'Withdraw Fund'}
      </h3>
      {updateDetails && (
        <div className={Classes.cover}>
          <div className={Classes.updateDetailsCover}>
            <strong>Account details are required</strong>
            <small style={{marginTop: '10px', textAlign: 'center'}}>
              Go to withdrawal settings and add your account details.
            </small>
          </div>
          <Link to="/settings?type=account">
            <Button
              text="Update Details"
              style={{margin: '30px auto 20px auto'}}
            />
          </Link>
        </div>
      )}
      {!confirm && (
        <form className={Classes.cover} onSubmit={formOnSubmitHandler}>
          <div className={Classes.amountCover}>
            <div className={Classes.nairaCover}>₦</div>
            <Input
              label="Amount"
              style={{paddingLeft: '60px'}}
              required
              marb={true}
              type="input"
              inputType="number"
            />
          </div>
          <Input label="Bank" readOnly required type="input" />
          <div className={Classes.gridTwo}>
            <Input readOnly label="Account Name" required type="input" />
            <Input readOnly label="Account Number" required type="input" />
          </div>

          <div className={Classes.flexRight}>
            <Button
              text="Withdraw"
              style={{width: '130px'}}
              loading={loading}
              loading={loading}
              showLoading={false}
              onClick={sendMessageHandler}
            />
            <Button
              text="Cancel"
              onClick={() => setShowModal(false)}
              style={{
                width: '130px',
                marginLeft: '20px',
                backgroundColor: '#fff',
                color: '#ff0066',
              }}
            />
          </div>
        </form>
      )}

      {confirm && (
        <>
          <div style={{marginBottom: '20px'}} className={Classes.textSection}>
            Are you sure you want to send <strong>₦0</strong> to the account
            below
          </div>
          <div style={{marginBottom: '7px'}} className={Classes.textSection}>
            Account name: <strong>Edet Mmenye Effiong</strong>
          </div>
          <div style={{marginBottom: '7px'}} className={Classes.textSection}>
            Account number <strong>045143902</strong>
          </div>
          <div className={Classes.textSection}>
            Bank <strong>Guaranty Trust Bank</strong>
          </div>
          <div className={Classes.flexRight} style={{marginTop: '10px'}}>
            <Button
              text="Yes, Withdraw"
              style={{width: '150px'}}
              loading={loading}
              loading={loading}
              showLoading={false}
              onClick={sendMessageHandler}
            />
            <Button
              text="Cancel Withdraw"
              onClick={() => setShowModal(false)}
              style={{
                width: '150px',
                marginLeft: '20px',
                backgroundColor: '#fff',
                color: '#ff0066',
              }}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default ModalSection;
