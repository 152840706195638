import React, {useRef, useState, useEffect, useContext} from 'react';
import { MainContext } from '../../App';
import Subscription from './subscription';

const EventSubscription = ({itemsPerPage = '8'}) => {
  const [currentItems, setCurrentItems] = useState([]);
  const CTX = useContext(MainContext)
  // const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [subscription, setSubscription] = useState([]);
  const refReactPaginate = useRef();
  const data = [
    {
      id: '2423lkj23l4jpi2',
      name: 'professional',
      type: 'monthly',
      amount: '3442',
      ex_date: '23/04/2022',
      date: '09/02/2022',
    },
    {
      id: '2423lkj23l4jpi2',
      name: 'professional',
      type: 'monthly',
      amount: '3442',
      ex_date: '23/04/2022',
      date: '09/02/2022',
    },
    {
      id: '2423lkj23l4jpi2',
      name: 'professional',
      type: 'monthly',
      amount: '3442',
      ex_date: '23/04/2022',
      date: '09/02/2022',
    },
    {
      id: '2423lkj23l4jpi2',
      name: 'professional',
      type: 'monthly',
      amount: '3442',
      ex_date: '23/04/2022',
      date: '09/02/2022',
    },
  ];

  const getAllSubscription = async () => {
    // setTimeout(() => {
    //   setSubscription(data);
    //   setLoading(false);
    // }, 4000);
    // setLoading(true);



    setLoading(true);
    const fetched = await fetch(`${CTX.url}/vendor/subscription/verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${CTX.token}`,
      },
    });
    const json = await fetched.json();

    setLoading(false);
    console.log("json.data => ", json.data);
  };

  useEffect(() => {
    getAllSubscription();
  }, []);

  useEffect(() => {
    if (subscription.length < 1) return;
    // Fetch items from another resources.carts
    const endOffset = itemOffset + itemsPerPage;
    //  console.log(`Loading carts from ${itemOffset} to ${endOffset}`);
    const allContent = subscription.slice(itemOffset, endOffset);
    const eachPageItem = allContent.splice(0, itemsPerPage);
    //  setCurrentItems(items.slice(itemOffset, endOffset));
    setCurrentItems(eachPageItem);
    setPageCount(Math.ceil(subscription.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, subscription]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setPageNum(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % subscription.length;
    setItemOffset(newOffset);
  };

  return (
    <Subscription
      currentItems={currentItems}
      loading={loading}
      pageCount={pageCount}
      pageNum={pageNum}
      showModal={showModal}
      setShowModal={setShowModal}
      refReactPaginate={refReactPaginate}
    />
  );
};

export default EventSubscription;
