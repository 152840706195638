import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import Login from './login';
import {MainContext} from '../../App';
import {usePaystackPayment} from 'react-paystack';

const EventLogin = () => {
  const CTX = useContext(MainContext);
  const [register, setRegister] = useState(false);
  const [msg, setMsg] = useState('');
  const [selectedStates, setSelectedStates] = useState('Abia');
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (register) {
        const fetched = await fetch(`${CTX.url}/vendor/register`, {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            email: inputs.email,
            first_name: inputs.first_name,
            last_name: inputs.last_name,
            state: selectedStates,
            phone_number: inputs.phone_number,
            store_name: inputs.store_name,
            terms: true,
            password: inputs.password,
          }),
        });

        if (fetched.status === 400) {
          setTimeout(() => {
            setMsg('');
            setInputs({email: '', password: '', first_name: '', last_name: ''});
          }, 5000);
          setMsg('Email address already exists');
          setLoading(false);
        } else {
          setLoading(false);
          const fetchJson = await fetched.json();
          setTimeout(() => {
            setMsg('');
            setInputs({email: '', password: '', first_name: '', last_name: ''});
          }, 5000);
          setMsg('Confirm your email address');
        }

        // const url = await axios.post(`${CTX.url}/customer/register`, {
        //     email: inputs.email,
        //     first_name: inputs.first_name,
        //     last_name: inputs.last_name,
        //     state: selectedStates,
        //     terms: true,
        //     password: inputs.password,
        //   });
        //   setLoading(false);

        //   console.log('data => ', url);

        return;
      }
      const url = await axios.post(`${CTX.url}/vendor/login`, {
        email: inputs.email,
        password: inputs.password,
        terms: true,

      });
      setLoading(false);

      //   console.log('data => ', url.data);
      CTX.setToken(url.data.data.access_token);
      CTX.setUser(url.data.data.vendor);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
      setTimeout(() => {
        setMsg('');
      }, 4000);
      setMsg('User not found');
    }
  };

  // toggle registerHandler
  const setRegisterHandler = () => {
    setRegister(!register);
    setInputs({email: '', password: '', first_name: '', last_name: ''});
  };



  return (
    <Login
      inputs={inputs}
      setInputs={setInputs}
      setSelectedStates={setSelectedStates}
      selectedStates={selectedStates}
      msg={msg}
      setMsg={setMsg}
      register={register}
      setRegister={setRegisterHandler}
      onSubmitHandler={onSubmitHandler}
      loading={loading}
      CTX={CTX}
      setLoading={setLoading}
      selectedStates={selectedStates}
    />
  );
};

export default EventLogin;
