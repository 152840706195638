import React, {Suspense, useContext, useState} from 'react';
import {MainContext} from '../../App';
import Sidebar from '../../component/layout/sidebar/sidebar';
import LazyLoading from '../../component/lazyLoading/lazyLoading';
import Classes from './products.module.css';
import {EachPageHeader} from '../../component/layout/eachPageHeader/eachPageHeader';

import Button from '../../component/button/button';
import {AiOutlineSearch} from 'react-icons/ai';
import {ReactComponent as Trash} from '../../asset/trash.svg';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import {ReactComponent as Eye} from '../../asset/openeye.svg';
import {ReactComponent as Edit} from '../../asset/edit.svg';

import ReactPaginate from 'react-paginate';
import {Helmet} from 'react-helmet';
import {ReactComponent as Left} from '../../asset/left.svg';
import ModalSection from './modalSection';

const Products = ({
  showModal,
  setShowModal,
  loading,
  currentItems,
  pageNum,
  pageCount,
  refReactPaginate,
  handlePageClick,
  isEdit,
  setIsEdit,
  category,
  reGetProducts,
  deleteProduct,
  msg,
  searchInput,
  setSearchInput,
}) => {
  const CTX = useContext(MainContext);
  const [clicked, setClicked] = useState({});

  // mapping all the products here as |mappedProducts|
  const mappedProducts = currentItems
    .filter((v) => v.title.toLowerCase().includes(searchInput.toLowerCase()))
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <div className={Classes.myOrderItemCover}>
          <img
            src={v.thumbnail}
            className={Classes.imgSrc}
            alt="product image"
          />
          <strong className={Classes.strongSection}> Name: </strong>
          <span style={{margin: ' 0px 10px', textTransform: 'capitalize'}}>
            {v.title}
          </span>
        </div>
        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}>Category: </strong>
          {v.category_detail.name}
        </div>
        <div className={Classes.myOrderItemCover}>₦{v.price}</div>
        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}>Date Added: </strong>
          {v.created.split('T')[0]}
        </div>
        <div className={Classes.myOrderItemCover}>
          <button className={Classes.visibilityCover}>
            {v.stock ? 'visible' : 'hidden'}
          </button>
        </div>
        <div className={Classes.myOrderItemCover}>
          <p className={Classes.actionBTNCover}>
            <Trash
              onClick={deleteProduct.bind(this, v.pk)}
              fill="#7f7f7f"
              width="16px"
            />
            <Edit
              onClick={() => {
                setIsEdit(true);
                setClicked(v);
                setShowModal(true);
              }}
            />
            <Eye />
          </p>
        </div>
      </div>
    ));

  const mappedLoading = Array(7)
    .fill('a')
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <Skeleton width="100%" height="100%" />
      </div>
    ));

  return (
    <div className={Classes.flexSection}>
      <Helmet>
        <title>Products - Passward Vendor </title>
        <meta name="og:title" content="Products - Passward Vendor" />
        <meta name="og:description" content="Products - Passward Vendor" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <Sidebar />
      <div
        onClick={CTX.removeSideBar}
        style={{
          padding: '0px 20px',
          boxSizing: 'border-box',
          width: '100%',
          filter: 'blur(var(--body-blur,0px))',
        }}
      >
        <Suspense fallback={<LazyLoading />}>
          {/* nav section here is just the top header side */}
          <EachPageHeader first="Home" second="Account" third="Products" />
          <div className={Classes.subNavCover}>
            <form className={Classes.firstForm}>
              <input
                placeholder="Search  Product"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                className={Classes.searchFormSection}
              />
              <button className={Classes.buttonFormSection}>
                <AiOutlineSearch fill="#fff" size="1.3em" />
              </button>
            </form>

            <div className={Classes.PageHeaderCover}>
              <h3 className={Classes.eachPageHeader}>Products</h3>
              <div className={Classes.sideEachPageHeader}>
                <form className={Classes.secondForm}>
                  <input
                    placeholder="Search  Product"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    className={Classes.searchFormSection}
                  />
                  <button className={Classes.buttonFormSection}>
                    <AiOutlineSearch fill="#fff" size="1.3em" />
                  </button>
                </form>
                <Button
                  onClick={() => {
                    setShowModal(true);
                    setIsEdit(false);
                  }}
                  text="Add New Product"
                  style={{height: 'max-content', marginTop: '0px'}}
                />
              </div>
            </div>
          </div>

          {/* here is the listing of all the products with the headers */}
          {!mappedProducts.length < 1 && (
            <div className={Classes.cardCover}>
              {msg.length > 0 && (
                <div
                  className={Classes.msgAlert}
                  style={{textAlign: 'center', marginBottom: '40px !important'}}
                >
                  <p style={{margin: '0px 0px'}} className={Classes.msgItself}>
                    {msg}
                  </p>
                </div>
              )}

              <div className={Classes.mappedStoresCover}>
                <div className={Classes.cardListingCover}>
                  <div className={Classes.cardHeader}>
                    <strong style={{marginRight: 'auto'}}>Product Name</strong>
                    <strong style={{marginRight: 'auto'}}>Category</strong>
                    <strong style={{marginRight: 'auto'}}>Price</strong>
                    <strong>Date added</strong>
                    <strong>Status</strong>
                    <strong>Action</strong>
                  </div>

                  {loading ? mappedLoading : mappedProducts}
                </div>
              </div>
            </div>
          )}

          {/* if there is no products.. this shows */}
          {!loading && (
            <>
              {mappedProducts.length < 1 && (
                <div className={Classes.noProducts}>
                  <p>You do not have any products currently</p>
                </div>
              )}
            </>
          )}

          {!mappedProducts.length < 1 && (
            <div style={{display: 'flex', marginBottom: '30px', width: '100%'}}>
              <div className={Classes.showingCover}>
                Showing {pageNum} of {pageCount}
              </div>

              <ReactPaginate
                ref={refReactPaginate}
                breakLabel="..."
                nextLabel={<Left style={{transform: 'rotate(180deg)'}} />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<Left />}
                className={Classes.paginationClass}
                renderOnZeroPageCount={null}
                disabledClassName={Classes.disabledClassName}
              />
            </div>
          )}
        </Suspense>
      </div>
      <ModalSection
        showModal={showModal}
        setShowModal={setShowModal}
        isEdit={isEdit}
        clicked={clicked}
        clearClicked={() => setClicked({})}
        category={category}
        reGetProducts={reGetProducts}
        // setCategory={setCategory}
      />
    </div>
  );
};

export default Products;
