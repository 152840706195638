import React, {Suspense, useContext} from 'react';
import Classes from './wallet.module.css';
import DashboardCard from '../../component/dashboardCard/dashboardCard';
import Sidebar from '../../component/layout/sidebar/sidebar';
import LazyLoading from '../../component/lazyLoading/lazyLoading';
import {AiOutlineSearch} from 'react-icons/ai';
import {EachPageHeader} from '../../component/layout/eachPageHeader/eachPageHeader';
import {ReactComponent as Money} from '../../asset/money.svg';
// import {ReactComponent as Store} from '../../asset/store.svg';
import {ReactComponent as Box} from '../../asset/box.svg';
// import {ReactComponent as Bag} from '../../asset/bag.svg';
// import {ReactComponent as Left} from '../../asset/left.svg';
import {MainContext} from '../../App';
import {AiOutlineWallet} from 'react-icons/ai';
import Button from '../../component/button/button';

import ReactPaginate from 'react-paginate';
import {ReactComponent as Left} from '../../asset/left.svg';
import ModalSection from './modalSection';
import Skeleton from 'react-loading-skeleton';
import {Helmet} from 'react-helmet';

const Wallet = ({
  setShowModal,
  currentItems,
  pageNum,
  pageCount,
  refReactPaginate,
  handlePageClick,
  loading,
  showModal,
}) => {
  const CTX = useContext(MainContext);

  const mappedTransaction = currentItems.map((v, i) => (
    <div key={i} className={Classes.mappedOrders}>
      <div className={Classes.myOrderItemCover}>{v.date}</div>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>Transaction Method: </strong>
        {v.method}
      </div>

      <div className={Classes.myOrderItemCover}>
        {v.status ? (
          <button className={Classes.trueVisibilityCover}>paid</button>
        ) : (
          <button
            style={{backgroundColor: '#ff6251'}}
            className={Classes.trueVisibilityCover}
          >
            pending
          </button>
        )}
      </div>
      <div className={Classes.myOrderItemCover}>₦{v.price}</div>
    </div>
  ));

  const mappedLoading = Array(5)
    .fill('s')
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <Skeleton width="100%" height="100%" />
      </div>
    ));

  return (
    <div className={Classes.flexSection}>
      <Helmet>
        <title>Wallet - Passward Vendor </title>
        <meta name="og:title" content="Wallet - Passward Vendor" />
        <meta name="og:description" content="Wallet - Passward Vendor" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <Sidebar />
      <div
        onClick={CTX.removeSideBar}
        style={{
          padding: '0px 20px',
          boxSizing: 'border-box',
          width: '100%',
          filter: 'blur(var(--body-blur,0px))',
        }}
      >
        <Suspense fallback={<LazyLoading />}>
          {/* nav section here is just the top header side */}
          <EachPageHeader first="Home" second="Account" third="Wallet" />
          <div className={Classes.gridDashboardCard}>
            <DashboardCard
              label="Wallet Balance"
              num="N5000.00"
              svg={<AiOutlineWallet />}
            />
            <DashboardCard
              label="Total Revenue"
              num="N5000.00"
              svg={<Money />}
            />
            <DashboardCard
              label="Customers"
              num="2"
              svg={<Box width="16px" />}
            />
          </div>

          <div className={Classes.subNavCover}>
            <form className={Classes.firstForm}>
              <input
                placeholder="Search Wallet"
                className={Classes.searchFormSection}
              />
              <button className={Classes.buttonFormSection}>
                <AiOutlineSearch fill="#fff" size="1.3em" />
              </button>
            </form>
            <div className={Classes.PageHeaderCover}>
              <h3 className={Classes.eachPageHeader}>Wallet Transaction</h3>
              <div className={Classes.sideEachPageHeader}>
                <form className={Classes.secondForm}>
                  <input
                    placeholder="Search  Wallet"
                    className={Classes.searchFormSection}
                  />
                  <button className={Classes.buttonFormSection}>
                    <AiOutlineSearch fill="#fff" size="1.3em" />
                  </button>
                </form>
                <Button
                  onClick={() => setShowModal(true)}
                  text="Withdraw Fund"
                  style={{height: 'max-content', marginTop: '0px'}}
                />
              </div>
            </div>
          </div>

          {/* main order sectioon where the orders are mapped */}
          <div className={Classes.cardCover}>
            <div className={Classes.mappedStoresCover}>
              <div className={Classes.cardListingCover}>
                <div className={Classes.cardHeader}>
                  <strong>Transaction Date</strong>
                  <strong>Transaction Method</strong>
                  <strong>Status</strong>
                  <strong>Price</strong>
                </div>

                {loading ? mappedLoading : mappedTransaction}
              </div>
            </div>
          </div>

          <div style={{display: 'flex', marginBottom: '30px', width: '100%'}}>
            <div className={Classes.showingCover} style={{marginTop: '8px'}}>
              Showing {pageNum} of {pageCount}
            </div>

            <ReactPaginate
              ref={refReactPaginate}
              breakLabel="..."
              nextLabel={<Left style={{transform: 'rotate(180deg)'}} />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={<Left />}
              className={Classes.paginationClass}
              renderOnZeroPageCount={null}
              disabledClassName={Classes.disabledClassName}
            />
          </div>
        </Suspense>
      </div>
      <ModalSection showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default Wallet;
