import React, {useEffect, useState, useContext} from 'react';
import Classes from './withdrawalSettings.module.css';
import {
  AiOutlineUser,
  AiFillSave,
} from 'react-icons/ai';
import Input from '../../component/input/input';
// import Card from '../../component/card/card';
import Button from '../../component/button/button';
import {Banks} from '../../data/bankJson';
import axios from 'axios';
import {MainContext} from '../../App';
import {GetUserDetails} from '../../helper/getUserHelper';

const Withdrawal = () => {
  const {loading, getUser} = GetUserDetails();
  const CTX = useContext(MainContext);
    const [withdrawalInput, setWithdrawalInput] = useState({
      ...CTX.user,
      account_num: CTX.user?.account_no,
    });
  const [loadingFetch, setLoading] = useState(false);
  const [selectedBank, setSelectedBank] = useState('Abbey Mortgage Bank');
  const [msg, setMsg] = useState('');


    // submiting the bank details with axios fetch
    const onSubmitBankHandler = async (e) => {
        e.preventDefault();
    
        setLoading(true);
        try {
          const request = await axios({
            url: `${CTX.url}/vendor/setting/account`,
            method: 'put',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${CTX.token}`,
            },
            data: {
              bank: selectedBank,
              account_no: withdrawalInput.account_num,
              account_name: withdrawalInput.account_name,
            },
          });
          console.log('request', request);
          setLoading(false);
          setTimeout(() => {
            setMsg('');
          }, 4000);
          setMsg('Details saved successfully');
          const sad = getUser();
        } catch (error) {
          setLoading(false);
          setTimeout(() => {
            setMsg('');
          }, 4000);
          setMsg('Invalid details');
          console.log(error);
        }
      };

      

    return (
        <form onSubmit={onSubmitBankHandler}>
              <div className={Classes.personalInfoCover}>
                <AiOutlineUser style={{marginRight: '10px', lineHeight: '1'}} />
                <span style={{lineHeight: '1'}}>ACCOUNT DETAILS</span>
              </div>
              <small style={{textAlign: 'center', color: '#6c757d'}}>
                All wallet withdrawals will be sent to the account below.
              </small>
              <Input
                type="select"
                options={Banks.map((v) => {
                  return v.name;
                })}
                selected={withdrawalInput?.bank}
                label="Bank"
                onChange={(e) => setSelectedBank(e.target.value)}
              />
              <div className={Classes.gridTwo}>
                <Input
                  required
                  value={withdrawalInput?.account_name}
                  onChange={(e) =>
                    setWithdrawalInput({
                      ...withdrawalInput,
                      account_name: e.target.value,
                    })
                  }
                  type="input"
                  inputType="text"
                  label="Account Name"
                />
                <Input
                  required
                  value={withdrawalInput?.account_num}
                  onChange={(e) =>
                    setWithdrawalInput({
                      ...withdrawalInput,
                      account_num: e.target.value,
                    })
                  }
                  type="input"
                  label="Account No"
                />
              </div>

              <Button
                svg={
                  loadingFetch ? (
                    ''
                  ) : (
                    <AiFillSave style={{marginRight: '5px'}} />
                  )
                }
                text={msg.length > 1 ? msg : 'Save Changes'}
                loading={loadingFetch}
                bgColor={loadingFetch ? '#1abc9c98' : '#1abc9c'}
              />
            </form>
    )
}

export default Withdrawal
